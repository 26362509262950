<template>
  <div class="columnList">
    <div class="conent">
      <!-- 栏目页大图 -->
      <div class="lbt">
        <img src="~@/assets/img/banner.jpg" />
      </div>
      <div class="tjlm_bg">
        <div class="tjlm">
          <ul v-for="(item, index) in tablist" :key="index">
            <li :id="item.id" :class="cuIdx === index ? 'tjlm_on' : ''">
              <a v-if="item.url" :href="item.url" target="_blank">{{
                item.id
              }}</a>
              <span
                :title="item.id"
                v-else
                @click="reload(item.components, index)"
                >{{ item.id }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <component v-if="Refresh" :is="currentTabComponent" class="w1380 lmy"></component>
    </div>
  </div>
</template>

<script>
import xxjj from "./xxjj.vue";
import zmxz from "./zmxz.vue";
import lrld from "./lrld.vue";
import ldgc from "./ldgc.vue";
import xrld from "./xrld.vue";
import zzjg from "./zzjg.vue";
import xxgk from "./xxgk.vue";
export default {
  components: {
    xxjj,
    zmxz,
    lrld,
    ldgc,
    xrld,
    zzjg,
    xxgk,
  },
  data() {
    return {
      cuIdx: 0,
      Refresh:true,
      tablist: [
        {
          id: "学校简介",
          components: "xxjj",
        },
        {
          id: "领导视察",
          components: "ldgc",
        },
        {
          id: "知名学者",
          components: "zmxz",
        },
        {
          id: "学校领导",
          components: "xrld",
        },
        // {
        //   id: "历任领导",
        //   components: "lrld",
        // },
        {
          id: "组织机构",
          components: "zzjg",
        },
        {
          id: "校史图志",
          components: "lrld",
        },
        {
          id: "信息公开",
          components: "xxgk",
        },
      ],
      currentTabComponent: "xxjj",
    };
  },
  created() {
    this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
    this.currentTabComponent = this.tablist[this.cuIdx].components;
    this.getColumnFun();
  },
  methods: {
    reload(component, index) {
      this.Refresh = false
      this.cuIdx = index;
      this.currentTabComponent = component;
      this.$nextTick(()=>{
        this.Refresh = true
      })
    },
    async getColumnFun() {
      const params = {
        pageNumber: 0,
        pageSize: 1,
        columnIds: this.$columnIdFile.xxgkxstz,
      };
      const res = await this.API.basic.getHomeRotographList(params);
      this.tablist[6].url =
        res.data.content[0].mapProperties.erOriginLink ||
        "https://www.bisu.edu.cn/wlxsg/";
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.columnList {
  .conent .lbt {
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
}
</style>
