<template>
  <div class="xz_leader page_cut page_active" id="leader-all">
    <template v-if="type == 'list'">
      <!-- 第一栏领导 -->
      <div class="column column_1" style="padding-top: 2%">
        <img src="@/assets/img/xrld/colum1.png" class="left-bg" />
        <div class="xz_leader_item" @click="open(1)">
          <div class="left-bg"></div>
          <div class="xz_leader_left">
            <img class="xz_leader_image" src="@/assets/img/xrld/1_1.png" />
          </div>
          <div class="left-bg_hua"></div>
          <img src="@/assets/img/xrld/line.png" class="xz_line" />
          <div class="xz_leader_right">
            <div class="leader_duties">党委书记</div>
            <div class="leader_name">常宇</div>
          </div>
        </div>
        <div class="xz_border"></div>
        <div class="xz_leader_item" @click="open(2)">
          <div class="xz_leader_left">
            <img
              class="xz_leader_image"
              src="@/assets/img/xrld/1_2.png"
              style="width: 8.64583vw; height: 12.03125vw; position: relative; left: 0.10417vw"
            />
          </div>
          <div class="left-bg_hua"></div>
          <img src="@/assets/img/xrld/line.png" class="xz_line" />
          <div class="xz_leader_right">
            <div class="leader_duties">党委副书记、院长</div>
            <div class="leader_name">计金标</div>
          </div>
        </div>
        <img class="xz_right_border" src="@/assets/img/xrld/right.png" />
      </div>

      <!-- 第二栏领导 -->
      <div class="column column_2">
        <img src="@/assets/img/xrld/colum2.png" class="left-bg" />
        <div class="left">
          <div class="xz_leader_item" @click="open(3)">
            <div class="xz_leader_left">
              <img class="xz_leader_image" src="@/assets/img/xrld/2_1.png" />
            </div>
            <div class="left-bg_hua"></div>
            <img src="@/assets/img/xrld/line.png" class="xz_line" />
            <div class="xz_leader_right">
              <div class="leader_duties">党委副书记</div>
              <div class="leader_name">王秀彦</div>
            </div>
            <div class="footer_border"></div>
          </div>
          <div class="xz_leader_item" @click="open(4)">
            <div class="xz_leader_left">
              <img class="xz_leader_image" src="@/assets/img/xrld/2_3.png" />
            </div>
            <div class="left-bg_hua"></div>
            <img src="@/assets/img/xrld/line.png" class="xz_line" />
            <div class="xz_leader_right">
              <div class="leader_duties">党委副书记</div>
              <div class="leader_name">李臻</div>
            </div>
            <div class="footer_border"></div>
          </div>
          <div
            class="xz_leader_item"
            @click="open(5)"
            style="margin-bottom: 0; padding-bottom: 0"
          >
            <div class="xz_leader_left">
              <img class="xz_leader_image" src="@/assets/img/xrld/2_5.png" />
            </div>
            <div class="left-bg_hua"></div>
            <img src="@/assets/img/xrld/line.png" class="xz_line" />
            <div class="xz_leader_right">
              <div class="leader_duties">党委常委、纪委书记</div>
              <div class="leader_name">滕继辉</div>
            </div>
          </div>
        </div>
        <div class="middle"></div>
        <div class="right">
          <div class="xz_leader_item" @click="open(6)">
            <div class="xz_leader_left">
              <img class="xz_leader_image" src="@/assets/img/xrld/2_2.png" />
            </div>
            <div class="left-bg_hua"></div>
            <img src="@/assets/img/xrld/line.png" class="xz_line" />
            <div class="xz_leader_right">
              <div class="leader_duties">党委常委、副院长</div>
              <div class="leader_name">张严</div>
            </div>
            <div class="footer_border"></div>
          </div>
          <div class="xz_leader_item" @click="open(7)">
            <div class="xz_leader_left">
              <img class="xz_leader_image" src="@/assets/img/xrld/2_4.png" />
            </div>
            <div class="left-bg_hua"></div>
            <img src="@/assets/img/xrld/line.png" class="xz_line" />
            <div class="xz_leader_right">
              <div class="leader_duties">党委常委、副院长</div>
              <div class="leader_name">程维</div>
            </div>
            <div class="footer_border"></div>
          </div>
          <div
            class="xz_leader_item"
            @click="open(8)"
            style="margin-bottom: 0; padding-bottom: 0"
          >
            <div class="xz_leader_left">
              <img class="xz_leader_image" src="@/assets/img/xrld/2_6.jpg" />
            </div>
            <div class="left-bg_hua"></div>
            <img src="@/assets/img/xrld/line.png" class="xz_line" />
            <div class="xz_leader_right">
              <div class="leader_duties">党委常委、副院长</div>
              <div class="leader_name">郑承军</div>
            </div>
          </div>
        </div>
        <img class="xz_right_border" src="@/assets/img/xrld/right.png" />
      </div>

      <!-- 第三栏领导 -->
      <div class="column column_3">
        <img src="@/assets/img/xrld/colum3.png" class="left-bg" />
        <div class="xz_leader_item" @click="open(9)">
          <div class="xz_leader_left">
            <img class="xz_leader_image" src="@/assets/img/xrld/3_1.png" />
          </div>
          <div class="left-bg_hua"></div>
          <img src="@/assets/img/xrld/line.png" class="xz_line" />
          <div class="xz_leader_right">
            <div class="leader_duties">党委常委</div>
            <div class="leader_name">常静</div>
          </div>
        </div>
        <div class="xz_leader_item" @click="open(10)">
          <div class="xz_leader_left">
            <img class="xz_leader_image" src="@/assets/img/xrld/3_2.png" />
          </div>
          <div class="left-bg_hua"></div>
          <img src="@/assets/img/xrld/line.png" class="xz_line" />
          <div class="xz_leader_right">
            <div class="leader_duties">党委常委</div>
            <div class="leader_name">张喜华</div>
          </div>
        </div>
        <div class="xz_leader_item" @click="open(11)">
          <div class="xz_leader_left">
            <img class="xz_leader_image" src="@/assets/img/xrld/3_3.png" />
          </div>
          <div class="left-bg_hua"></div>
          <img src="@/assets/img/xrld/line.png" class="xz_line" />
          <div class="xz_leader_right">
            <div class="leader_duties">院长助理</div>
            <div class="leader_name">邹统钎</div>
          </div>
        </div>
        <img class="xz_right_border" src="@/assets/img/xrld/right.png" />
      </div>

      <div class="xz_footer">
        <img src="@/assets/img/xrld/xz_footer.png" class="xz_footer_img" />
      </div>
    </template>
    <template v-else>
      <div class="header-line">
        <span class="point-class" @click="type = 'list'">首页</span>
        <span> &gt; </span>
        <span class="point-class" @click="type = 'list'">学校领导</span>
      </div>
      <component :is="currentTabComponent"></component>
    </template>
  </div>
</template>
<script>
import Item1 from "./detail/item1.vue";
import Item2 from "./detail/item2.vue";
import Item3 from "./detail/item3.vue";
import Item4 from "./detail/item4.vue";
import Item5 from "./detail/item5.vue";
import Item6 from "./detail/item6.vue";
import Item7 from "./detail/item7.vue";
import Item8 from "./detail/item8.vue";
import Item9 from "./detail/item9.vue";
import Item10 from "./detail/item10.vue";
import Item11 from "./detail/item11.vue";
export default {
  components: {
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
    Item11,
  },
  data() {
    return {
      type: "list",
      currentTabComponent: "",
    };
  },
  methods: {
    open(index) {
      this.type = "detail";
      this.currentTabComponent = "Item" + index;
    },
  },
};
</script>

<style lang="scss" scoped>
@charset "UTF-8";

.xz_leader {
  width: 100%;
  margin: auto;
  background: #fff;
}
.xz_right_border {
  /* box-shadow: 13px 14px 8px -2px rgba(248,248, 248, 1); */
  position: absolute;
  top: 10px;
  right: 0;
  height: 98%;
  width: 1.4%;
  background: rgba(248, 248, 248, 1);
}

.xz_leader .column {
  position: relative;
}
.column_1 .left-bg_hua {
  //background-image: url(../images/xz_huadi.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 179px;
  height: 201px;
  margin-left: -20%;
}

.xz_leader .column_1 {
  /* background-image: url('../images/33.png'); */
  margin: auto;
  /* box-shadow: 25px 25px 8px -2px rgba(227,227, 227, .5); */
  padding: 5%;
  padding-bottom: 0%;
  padding-top: 1%;
  box-shadow: 19px 19px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 19px 19px 12px 0 rgba(227, 227, 227, 0.5);
}
/* .xz_leader_left{
    background-image: url(../img/xz_huadi.png);
    background-size: inherit;
    background-repeat: no-repeat;
    background-position-x: 100%;
} */
.xz_leader .column_1 .xz_leader_item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 15%;
  margin-right: 10%;
  cursor: pointer;
}

.xz_leader .column_1 .xz_leader_left {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}
.xz_leader .column_1 .xz_leader_right {
  text-align: center;
}

.xz_leader .column_1 .xz_border {
  height: 1px;
  width: 87%;
  background-color: #d6d6d6;
  margin: 20px auto;
}

.xz_leader .column_1 .xz_leader_image {
  height: 201px;
  /* max-width: 120px; */
}

.xz_leader .column_1 .leader_duties {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
  min-width: 200px;
}

.xz_leader .column_1 .leader_name {
  font-size: 22px;
  font-weight: bold;
}

.xz_leader .column_1 .left-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 46px;
}
.column_2 .left-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 46px;
}
.column_3 .left-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 46px;
}

.xz_leader .column_2 {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  /* background-image: url(../img/xz_yy.png); */
  background-size: 100% 100%;
  margin: auto;
  padding-bottom: 10px;
  box-shadow: 25px 30px 8px -2px rgba(227, 227, 227, 0.5);
  margin-top: 30px;
  padding: 5%;
  box-shadow: 19px 19px 12px 0 rgba(227, 227, 227, 0.5);
}

.xz_leader .column_2 .xz_leader_item {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* justify-content: space-evenly; */
  justify-content: center;
  padding-bottom: 4%;
  position: relative;
  margin-bottom: 4%;
  cursor: pointer;
}

.xz_leader .column_2 .xz_leader_left {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.xz_leader .column_2 .xz_line {
  margin-left: 8%;
  margin-right: 7%;
}

.xz_leader .column_2 .xz_leader_right {
  text-align: center;
}

.xz_leader .column_2 .xz_border {
  height: 1px;
  width: 87%;
  background-color: #d6d6d6;
  margin: 20px auto;
}

.xz_leader .column_2 .xz_leader_image {
  height: 150px;
  /* max-width: 120px; */
}

.xz_leader .column_2 .leader_duties {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
}

.xz_leader .column_2 .leader_name {
  font-size: 18px;
  font-weight: bold;
}

.column_2 .xz_line {
}

.xz_leader .column_3 {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  box-shadow: 25px 30px 8px -2px rgba(227, 227, 227, 0.5);
  margin-top: 30px;
  padding: 5%;
  padding-bottom: 0;
  box-shadow: 19px 19px 12px 0 rgba(227, 227, 227, 0.5);
}

.xz_leader .column_3 .xz_leader_item {
  width: 33.33%;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-evenly;
  margin-bottom: 5%;
  cursor: pointer;
}

.xz_leader .column_3 .xz_leader_left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.xz_leader .column_3 .xz_leader_right {
  text-align: center;
}

.xz_leader .column_3 .xz_border {
  height: 1px;
  width: 87%;
  background-color: #d6d6d6;
  margin: 20px auto;
}

.xz_leader .column_3 .xz_leader_image {
  height: 150px;
}

.xz_leader .column_3 .leader_duties {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
}

.xz_leader .column_3 .leader_name {
  font-size: 18px;
  font-weight: bold;
}

.column_3 .left-bg_hua {
  // background-image: url(../images/xz_huadi.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 129px;
  height: 150px;
  margin-left: 0;
}
.column_2 .left-bg_hua {
  // background-image: url(../images/xz_huadi.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 129px;
  height: 150px;
  margin-left: -6%;
}
.xz_footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.xz_footer .xz_footer_img {
  /* width: 100%; */
}

.xz_footer .xz_footer_con {
  position: absolute;
}
.footer_border {
  height: 1px;
  width: 80%;
  background-color: #d6d6d6;
  position: absolute;
  bottom: 0%;
}
.column_2 .left {
  display: flex;
  flex-direction: column;
  width: 49.5%;
  justify-content: space-evenly;
  align-items: center;
}
.column_2 .middle {
  width: 1px;
  background-color: #d6d6d6;
  margin: 3% 0;
}
.column_2 .right {
  display: flex;
  flex-direction: column;
  width: 49.5%;
  justify-content: space-evenly;
  align-items: center;
}
.xz-main .mobile_top_banner {
  display: none;
}
.xz_leader .column_2 .xz_leader_right {
  min-width: 160px;
}
.header-line {
  font-size: 16px;
  padding-left: 20px;
  padding-top: 20px;
  cursor: pointer;
}
/* mobile */
@media screen and (max-width: 768px) {
  .xz_leader .column_2 {
    flex-direction: column;
    width: 100%;
  }
  .w1380 {
    width: 100%;
  }
  .xz-main .mobile_top_banner {
    display: block;
  }
  .column_2 .left {
    width: 100%;
  }
  .column_2 .right {
    width: 100%;
  }
  .column_2 .middle {
    width: 100%;
  }
  .column_2 .footer_border {
    display: none;
  }
  .xz_leader {
    width: 100%;
  }
  .xz_leader .column_2 .xz_leader_item {
    width: 100% !important;
  }
  .xz_leader .column_3 .xz_leader_item {
    width: 100%;
  }
  .xz_leader .column_3 .left-bg {
    width: 30px;
  }
  .xz_leader .column_1 .left-bg {
    width: 30px;
  }
  .xz_leader .column_2 .left-bg {
    width: 30px;
  }
  .xz_footer .xz_footer_img {
    width: 100%;
  }
  .xz_footer .xz_footer_con {
    width: 100%;
  }
  .xz_line {
    display: none;
  }
  .xz_leader_right {
    min-width: 180px;
  }
  .xz_leader .column_1 .xz_leader_item {
    margin: 0;
  }
  .xz_leader .column_1 .xz_leader_left {
    min-width: 216px;
  }
  .xz_leader .column_1 .xz_leader_right {
    position: relative;
    left: -30px;
  }
}
</style>
