<template>
  <div class="jcxz">
    <div class="page-herader">
      <span>{{ $t("source_6") }}</span>
    </div>
    <div class="lmy-center">
      <div class="jcxz_item">
        <!-- <div class="teachers_lanmu">职能部门</div> -->
        <div class="teachers_name">
          <ul class="teachers_lb" v-if="!$route.query.detail">
            <li v-for="(item, index) in list" :key="index">
              <a
                v-if="item.mapProperties?.erOriginLink"
                :href="item.mapProperties?.erOriginLink"
                target="_blank"
                >{{ item.title }}</a
              >
              <a
                v-else
                :href="`${pathname}#/xxDetail?id=7&detail=${index}`"
                target="_blank"
                >{{ item.title }}</a
              >
            </li>
          </ul>
          <div v-if="$route.query.detail && list.length > 0" class="detailBox">
            <div
              v-if="content.primersTitle"
              style="
                padding: 0 0 0.52083vw 0;
                font-size: 1.45833vw;
                color: #333333;
                font-weight: 500;
              "
            >
              {{ content.primersTitle }}
            </div>
            <div class="wzbt">
              <p class="wzbt_title">{{ list[$route.query.detail].title }}</p>
            </div>
            <div
              v-if="content.subTitle"
              style="
                padding: 1.04167vw 1.04167vw 0.52083vw 1.04167vw;
                font-size: 1.45833vw;
                color: #333333;
                text-align: right;
                font-weight: 500;
              "
            >
              {{ content.subTitle }}
            </div>
            <div class="wz-qt">
              <!-- <span style="margin-left: 0.52083vw"
                >来源：<span style="color: #6c6a6a">财务与资产处</span></span
              > -->
              <span style="margin-left: 0.52083vw">
                <div
                  class="content_source"
                  style="
                    padding: 0.83333vw 0 0 3.64583vw;
                    font-size: 0.83333vw;
                    color: #6c6a6a;
                  "
                >
                  <el-divider direction="vertical"></el-divider>
                  <span v-if="content?.mapProperties.wenzi"
                    >{{ $t("source_2") }}：{{
                      content.mapProperties.wenzi
                    }}</span
                  >
                  <span v-if="content?.mapProperties.sheying"
                    >{{ $t("source_3") }}：{{
                      content.mapProperties.sheying
                    }}</span
                  >
                  <span v-if="content.author"
                    >{{ $t("source_4") }}：{{ content.author }}</span
                  >
                  <span v-if="content.origin"
                    >{{ $t("source_1") }}：{{ content.origin }}</span
                  >
                  <span v-if="content.publishTime">{{
                    $moment(content.publishTime).format("YYYY-MM-DD")
                  }}</span>
                </div>
              </span>
              <span class="share_box"
                >{{ $t("source_7") }}：<span
                  class="share share-component social-share"
                  id="share-2"
                  ><a
                    class="social-share-icon icon-weibo"
                    href="http://service.weibo.com/share/share.php?url=https%3A%2F%2Fwww.bisu.edu.cn%2Fart%2F2022%2F9%2F15%2Fart_18916_302692.html&amp;title=%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A22021%E5%B9%B4%E5%BA%A6%E9%83%A8%E9%97%A8%E5%86%B3%E7%AE%97&amp;pic=https%3A%2F%2Fwww.bisu.edu.cn%2Fpicture%2F0%2F2012300115325015649.png&amp;appkey="
                    target="_blank"
                  ></a
                  ><a
                    class="social-share-icon icon-wechat"
                    href="javascript:;"
                    tabindex="-1"
                    ><div class="wechat-qcode">
                      <h4>{{ $t("source_8") }}</h4>
                      <div class="qcode">
                        <img src="../../assets/img/ewm.png" />
                      </div>
                      <div class="help">
                        <p>{{ $t("source_9") }}</p>
                        <p>{{ $t("source_10") }}</p>
                      </div>
                    </div></a
                  ><a
                    class="social-share-icon icon-qzone"
                    href="http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https%3A%2F%2Fwww.bisu.edu.cn%2Fart%2F2022%2F9%2F15%2Fart_18916_302692.html&amp;title=%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A22021%E5%B9%B4%E5%BA%A6%E9%83%A8%E9%97%A8%E5%86%B3%E7%AE%97&amp;desc=%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A22021%E5%B9%B4%E5%BA%A6%E9%83%A8%E9%97%A8%E5%86%B3%E7%AE%97%EF%BC%8C%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A22021%E5%B9%B4%E5%BA%A6%E9%83%A8%E9%97%A8%E5%86%B3%E7%AE%97%E6%8A%A5%E8%A1%A8%EF%BC%8C%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A2%E9%A1%B9%E7%9B%AE%E6%94%AF%E5%87%BA%E7%BB%A9%E6%95%88%E8%87%AA%E8%AF%84%E8%A1%A8&amp;summary=%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A22021%E5%B9%B4%E5%BA%A6%E9%83%A8%E9%97%A8%E5%86%B3%E7%AE%97%EF%BC%8C%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A22021%E5%B9%B4%E5%BA%A6%E9%83%A8%E9%97%A8%E5%86%B3%E7%AE%97%E6%8A%A5%E8%A1%A8%EF%BC%8C%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A2%E9%A1%B9%E7%9B%AE%E6%94%AF%E5%87%BA%E7%BB%A9%E6%95%88%E8%87%AA%E8%AF%84%E8%A1%A8&amp;site=%E5%8C%97%E4%BA%AC%E7%AC%AC%E4%BA%8C%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%AD%A6%E9%99%A22021%E5%B9%B4%E5%BA%A6%E9%83%A8%E9%97%A8%E5%86%B3%E7%AE%97"
                    target="_blank"
                  ></a></span
              ></span>
              <!-- <span style="float: right" class="size_change"
                >字号:[ <a href="javascript:doZoom(16)">大</a>&nbsp;
                <a href="javascript:doZoom(14)">中</a>&nbsp;
                <a href="javascript:doZoom(12)">小</a> ]</span
              > -->
            </div>
            <div
              class="content 1"
              style="font-size: 1.25vw"
              v-html="content.content"
            ></div>

            <div id="zoom" style="font-size: 0.83333vw" v-if="false">
              <meta name="ContentStart" />
              <div
                style="text-align: center"
                v-for="(item, index) in childList"
                :key="index"
              >
                <p
                  @click="download(item.url)"
                  style="
                    font-size: 1.04167vw;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  <span style="font-size: 1.04167vw">{{ item.title }}</span>
                </p>
              </div>
              <p><br /></p>
              <meta name="ContentEnd" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      childList: [],
      content: "",
      pathname: "",
    };
  },
  mounted() {
    this.getList();
    this.pathname = window.location.pathname;
  },
  methods: {
    download(url) {
      window.open(url);
    },
    async getList() {
      const params = {
        pageNumber: 0,
        pageSize: 100,
        columnIds: this.$columnIdFile.xxgkxxgk,
      };
      const res = await this.API.basic.getHomeRotographList(params);
      this.list = res.data.content;
      if (this.$route.query.detail) {
        this.getDetailsFun();
      }
    },
    async getDetailsFun() {
      const res = await this.API.basic.getManuscriptDetails(
        this.list[this.$route.query.detail].id
      );
      this.childList = res.data.attachedFiles;
      this.content = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.jcxz {
  margin-bottom: 50px;
}
.teachers_lanmu {
  padding: 10px 0 10px 25px;
  border-bottom: solid 1px #d6d6d6;
  line-height: 25px;
  font-size: 16px;
  font-family: "微软雅黑";
  background: url("~@/assets/img/jtIcon.png") 10px no-repeat;
  background-size: 10px 10px;
  color: #a73728;
}
.teachers_name {
  // overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.teachers_lb {
  font-size: 20px;
  padding: 10px 0;
  a{
    font-size: 20px;
  }
}
ul {
  display: flex;
  flex-wrap: wrap;
}
ul li {
  width: 420px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: solid 1px #e3e3e3;
  padding: 20px;
  a {
    color: #333;
    font-size: 20px;
    line-height: 20px;
  }
}
.detailBox {
  width: 100%;
  padding: 0 100px;
  .wzbt_title {
    font-size: 28px;
    text-align: center;
  }
  .wz-qt {
    font-size: 16px;
    color: #787878;
    padding-left: 10px;
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .share_box {
    float: right;
    margin-left: 10px;
  }
  #share-2 {
    float: right;
    margin-top: -13px;
    padding-left: 0 !important;
  }
  .social-share {
    font-family: "socialshare" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
  }
  .social-share .social-share-icon {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 40px;
    font-size: 16px;
    border-radius: 50%;
    line-height: 40px;
    color: #666;
    text-align: center;
    vertical-align: middle;
    transition: background 0.6s ease-out 0s;
    display: block;
    float: left;
  }
  .social-share a {
    position: relative;
    text-decoration: none;
    margin: 0 3px;
    display: inline-block;
    outline: none;
  }
  .social-share .icon-weibo {
    background: url("../../assets/img/iconWeibo.png") no-repeat center;
  }
  .social-share .icon-wechat {
    position: relative;
    background: url("../../assets/img/iconWechat.png") no-repeat center;
  }
  .social-share .icon-qzone {
    background: url("../../assets/img/iconQzone.png") no-repeat center;
  }
  .social-share .icon-wechat .wechat-qcode {
    display: none;
    border: 1px solid #eee;
    position: absolute;
    z-index: 999;
    top: -205px;
    left: -84px;
    width: 200px;
    height: 192px;
    color: #666;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 10px #aaa;
    transition: all 200ms;
    -webkit-tansition: all 350ms;
    -moz-transition: all 350ms;
  }
  .social-share .icon-wechat:hover .wechat-qcode {
    display: block;
  }
  .social-share .icon-wechat .wechat-qcode h4 {
    font-weight: normal;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    background-color: #f3f3f3;
    margin: 0;
    padding: 0;
    color: #777;
  }
  .social-share .icon-wechat .wechat-qcode .qcode {
    width: 105px;
    margin: 10px auto;
    img {
      box-sizing: border-box;
      height: auto;
      vertical-align: middle;
      border: 0;
    }
  }
  .social-share .icon-wechat .wechat-qcode .help p {
    font-weight: normal;
    line-height: 16px;
    padding: 0;
    margin: 0;
  }
  .social-share .icon-wechat .wechat-qcode:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -6px;
    bottom: -13px;
    width: 0;
    height: 0;
    border-width: 8px 6px 6px 6px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
  .size_change a {
    color: #333;
  }
  #zoom p {
    line-height: 200%;
    a {
      color: #333;
    }
  }
}
.content {
  margin-top: 37px;
  margin-bottom: 50px;
  ::v-deep img {
    max-width: 600px!important;
    margin: auto !important;
  }
  ::v-deep video {
    max-height: 500px;
  }
  ::v-deep a {
    color: rgb(108, 106, 106) !important;
  }
}
</style>
