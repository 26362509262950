<template>
  <div class="lrld">
    <div class="page-herader">
      <span>{{ $t("successive_leaders") }}</span>
    </div>
    <div style="font-size: 1.04167vw; padding: 1%">网页建设中</div>
    <!-- <div class="content 9">
      <div class="top-title">{{ $t('successive_leaders1') }}</div>
      <div v-for="(item, index) in list1" :key="index + 'a'" class="zmxz_item">
        <img
          :src="require(`../../assets/img/lrld/dw${index + 1}.jpg`)"
          style="height: 7.8125vw; max-width: 6.25vw; display: inline-block"
        />
        <div class="name1">{{ item.name }}</div>
        <div class="name1">{{ item.time }}</div>
      </div>
      <div class="top-title">{{ $t('successive_leaders2') }}</div>
      <div v-for="(item, index) in list2" :key="index + 'c'" class="zmxz_item">
        <img
          :src="require(`../../assets/img/lrld/xz${index + 1}.jpg`)"
          style="height: 7.8125vw; max-width: 6.25vw; display: inline-block"
        />
        <div class="name1">{{ item.name }}</div>
        <div class="name1">{{ item.time }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      list1: [
        {
          name: this.$t("leadershipName"),
          time: "1975.04—1978.01",
        },
        {
          name: this.$t("leadershipName1"),
          time: "1978.01—1978. 10",
        },
        {
          name: this.$t("leadershipName2"),
          time: "1979.03—1981.02",
        },
        {
          name: this.$t("leadershipName3"),
          time: "1979.03-1983.09",
        },
        {
          name: this.$t("leadershipName4"),
          time: "1983.09—1984.08",
        },
        {
          name: this.$t("leadershipName5"),
          time: "1984.08—1989.03",
        },
        {
          name: this.$t("leadershipName6"),
          time: "1989.03—1991.08",
        },
        {
          name: this.$t("leadershipName7"),
          time: "1991.08—1996.08",
        },
        {
          name: this.$t("leadershipName8"),
          time: "1996.08—1999.12",
        },
        {
          name: this.$t("leadershipName9"),
          time: "1999.12—2010.05",
        },
        {
          name: this.$t("leadershipName10"),
          time: "2010.05—2016.06",
        },
      ],
      list2: [
        {
          name: this.$t("leadershipName11"),
          time: "1964.10 — " + this.$t("leadershipName12"),
        },
        {
          name: this.$t("leadershipName13"),
          time: "1979.03—1983.09",
        },
        {
          name: this.$t("leadershipName14"),
          time: "1983.11—1987.04",
        },
        {
          name: this.$t("leadershipName15"),
          time: "1987.04—1989.01",
        },
        {
          name: this.$t("leadershipName16"),
          time: "1989.01—1991.08",
        },
        {
          name: this.$t("leadershipName17"),
          time: "1991.08—1999.12",
        },
        {
          name: this.$t("leadershipName18"),
          time: "1999.12—2006.04",
        },
        {
          name: this.$t("leadershipName19"),
          time: "2008.04—2014.11",
        },
        {
          name: this.$t("leadershipName20"),
          time: "2014.11— 2017.04",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #002bfd !important;
}
img {
  max-width: 100%;
}
.zmxz_item {
  width: 25%;
  text-align: center;
  padding: 10px 0;
  display: inline-block;
  overflow: hidden;
}
.top-title {
  padding: 10px 0 10px 25px;
  border-bottom: solid 1px #d6d6d6;
  line-height: 25px;
  font-size: 16px;
  background: url("~@/assets/img/xiao-title-icon.png") 10px no-repeat;
  color: #a73728;
}
.name1 {
  color: #a73827;
  line-height: 22px;
  padding: 5px 0;
  font-size: 14px;
}
</style>
