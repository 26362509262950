<template>
  <div class="xrld">
    <div class="page-herader">
      <span>学校领导</span>
    </div>
    <XrldItem />
    <!-- <div class="leader-clolum1">
      <img src="@/assets/img/xrld/colum1.png" class="left-bg" />
      <div class="leader-item"></div>
    </div>
    <div class="leader-clolum2">
      <img src="@/assets/img/xrld/colum2.png" class="left-bg" />
    </div>
    <div class="leader-clolum3">
      <img src="@/assets/img/xrld/colum3.png" class="left-bg" />
    </div> -->
    <!-- <div class="lmy-center">
      <div class="phone_dn d_left">
        <div class="d_top">现任领导</div>
        <ul class="d_leader" style="">
          <li
            v-for="(leader, ldx) in leaderList"
            :key="ldx"
            class="d_leader_item"
            @click="tabclick(ldx)"
          >
            <a :class="['d_a', ldx === curldx ? 'active' : '']">
              <span>{{ leader.title }}</span>
              <br />
              <span style="font-size: 0.72917vw"> {{ leader.description }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="phone_dn d_right">
        <div class="d_right_img" v-if="thumbnailInfoImg">
          <img :src="thumbnailInfoImg" alt="" />
        </div>
        <div v-html="leaderInfo"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import XrldItem from "./xrldItem.vue";
export default {
  components: { XrldItem },
  data() {
    return {
      curldx: 0,
      leaderList: [],
      leaderInfo: "",
      thumbnailInfoImg: "",
    };
  },
  created() {
    // this.getlist();
  },
  methods: {
    tabclick(ldx) {
      this.curldx = ldx;
      this.leaderInfo = this.leaderList?.[ldx].content;
      this.thumbnailInfoImg = this.leaderList?.[ldx].thumbnailInfo.url || null;
    },
    async getlist() {
      const res = await this.API.basic.getlist({
        columnIds: this.$columnIdFile.xxgkxrld,
        pageNumber: 0,
        pageSize: 100,
      });
      this.leaderList = res.data.content;
      this.leaderInfo = res.data.content?.[0].content;
      this.thumbnailInfoImg = res.data.content[0].thumbnailInfo.url || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;
  overflow: hidden;
  .d_left {
    width: 20%;
    float: left;
    border-left: 12px solid #982312;
    .d_top {
      background-color: #982312;
      padding: 10px 0;
      text-align: center;
      font-size: 22px;
      color: #fff;
    }
    .d_leader {
      border-top: 1px solid #ddd;
      margin-top: 20px;
      &_item {
        border-bottom: 1px solid #ddd;
        line-height: 22px;
        cursor: pointer;
        .d_a {
          padding: 10px 0 10px 22px;
          display: block;
          font-size: 17px;
          color: #333;
          &.active {
            color: #982312;
            border-left: 4px solid #ff9600;
            margin-left: -4px;
          }
        }
      }
    }
  }
  .d_right {
    width: 76%;
    float: right;
    .d_right_img {
      text-align: center;
      img {
        width: 200px !important;
      }
    }
    ::v-deep p {
      line-height: 200%;
      color: #333;
      font-family: 微软雅黑, Microsoft YaHei;
      font-size: 16px;
    }
    ::v-deep img {
      width: 200px !important;
      margin: auto;
    }
  }
}
</style>

<!-- 页面写死数据写死的样式 -->
<!-- <style lang="scss" scoped>
.leader-clolum1 {
  padding: 5%;
  box-shadow: 19px 19px 12px 0 rgba(227, 227, 227, 0.5);
  position: relative;
  .left-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 46px;
  }
}
.leader-clolum2 {
  padding: 5%;
  box-shadow: 19px 19px 12px 0 rgba(227, 227, 227, 0.5);
  position: relative;
  margin-top: 30px;
  .left-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 46px;
  }
}
.leader-clolum3 {
  padding: 5%;
  box-shadow: 19px 19px 12px 0 rgba(227, 227, 227, 0.5);
  position: relative;
  margin-top: 30px;
  .left-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 46px;
  }
}
</style> -->
