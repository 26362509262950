<template>
  <div class="jcxz">
    <div
      class="page-herader"
      style="cursor: pointer"
      @click="showType = 'list'"
    >
      <span>{{ showType === "list" ? "组织机构" : "查看更多" }}</span>
    </div>
    <div class="lmy-center">
      <template v-if="showType === 'list'">
        <div class="jcxz_item" v-for="(item, index) in columnList" :key="index">
          <div class="teachers_lanmu">{{ item.name }}</div>
          <div class="teachers_name">
            <ul class="teachers_lb">
              <li v-for="(i, inder) in item.dataList" :key="inder + 'a'">
                <a
                  :href="i.mapProperties.erOriginLink"
                  v-if="i.mapProperties.erOriginLink"
                  target="_blank"
                  >{{ i.title }}</a
                >
                <span v-else style="cursor: pointer" @click="show(i)">{{
                  i.title
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <TextDetail :content="showObject" />
      </template>
    </div>
  </div>
</template>

<script>
import TextDetail from "../textDetail";
export default {
  components: {
    TextDetail,
  },
  data() {
    return {
      columnList: [],
      showType: "list",
      showObject: {},
    };
  },
  mounted() {
    this.getColumnFun();
  },
  methods: {
    show(obj) {
      this.showObject = obj;
      this.showType = "detail";
    },
    async getColumnFun() {
      const res = await this.API.basic.getColumn();
      if (res.data.length) {
        let data = res.data;
        let columnListData = [];
        let findColumn = data.findIndex((i) => {
          return i.uuid == this.$columnIdFile.xxgk;
        });
        findColumn != "-1"
          ? (columnListData = data[findColumn].children)
          : (columnListData = []);
        let childrenFindColumn = columnListData.findIndex((i) => {
          return i.uuid == this.$columnIdFile.xxgkzzjg;
        });
        childrenFindColumn != "-1"
          ? (this.columnList = columnListData[childrenFindColumn].children)
          : (this.columnList = []);
        this.getManuscriptList();
      }
    },
    getManuscriptList() {
      this.columnList.map(async (li) => {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          columnIds: li.uuid,
        };
        const res = await this.API.basic.getHomeRotographList(params);
        li.dataList = res.data.content;
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.teachers_lanmu {
  padding: 10px 0 10px 25px;
  border-bottom: solid 1px #d6d6d6;
  line-height: 25px;
  font-size: 16px;
  font-family: "微软雅黑";
  background: url("~@/assets/img/jtIcon.png") 10px no-repeat;
  background-size: 10px 10px;
  color: #a73728;
}
.teachers_name {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.teachers_lb {
  font-size: 20px;
  padding: 10px 0;
  a{
    font-size: 20px;
  }
}
ul {
  display: flex;
  flex-wrap: wrap;
}
ul li {
  width: 420px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: solid 1px #e3e3e3;
  padding: 20px;
  a {
    color: #333;
    font-size: 20px;
    line-height: 20px;
  }
  span {
    color: #333;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
