<template>
  <div class="zmxz">
    <div class="page-herader">
      <span>{{title}}</span>
    </div>
    <div class="content 11">
      <!-- <div class="top-desc">
        编者按：作为有着光荣办学历史和出色办学成就的著名高等学府，北京第二外国语学院积淀了深厚的学术传统，有着独特的文化传承。五十余年来，学校形成了“融中外、
        兼知行”的学风，“和易以思、人文化成”的教风，营造了“传承东西文明，促进中外交流”的浓郁氛围，为国家和世界输送了一大批高素质人才，也涌现出一大批立德树人、治学严谨的名家名师。今天，我们整理了部分名家名师的档案材料，向大家推介他们闪耀在二外历史上的辉煌印迹，昭示他们在学术之路上的丰硕成就，从而进一步挖掘二外的优良办学传统和经验，传承好二外的学术命脉。
      </div> -->
      <template v-if="!details">
        <div v-for="(item, index) in list" :key="index">
          <div v-if="index == 0" class="top-desc">
            <div style="padding: 0 1.04167vw" v-html="item.content"></div>
          </div>
          <div v-else class="zmxz_item">
            <div class="zmxz_l">
              <img :src="item.thumbnailInfo.thumbnailUrl" style="width: 100%" />
            </div>
            <div class="zmxz_r">
              <div class="zmxz_t" @click="toDetail(item)">
                <a>{{ item.title }}</a>
              </div>
              <div class="zmxz_b" @click="toDetail(item)">
                <a>{{ item.description }}</a>
              </div>
              <div class="zmxz_d">{{ item.comment }}</div>
            </div>
          </div>
        </div>
      </template>
      <!-- 详情 -->
      <template v-if="details">
        <div class="xxjj">
          <div class="lmy-center">
            <div v-if="content.primersTitle" style="padding: 0 0 0.52083vw 0;font-size: 1.45833vw;color: #333333;font-weight: 500;">{{ content.primersTitle }}</div>
            <div style="font-size: 1.77083vw;text-align: center;">{{ content.title }}</div>
            <div v-if="content.subTitle" style="padding: 1.04167vw 1.04167vw 0.52083vw 1.04167vw;font-size: 1.45833vw;color: #333333;text-align: right;font-weight: 500;">{{ content.subTitle }}</div>
            <div class="content_source" style="padding: 0.83333vw 0 0 3.64583vw;font-size: 0.83333vw;color: #6c6a6a;">
              <el-divider direction="vertical"></el-divider>
              <span v-if="content.mapProperties.wenzi">{{ $t('source_2') }}：{{ content.mapProperties.wenzi }}</span>
              <span v-if="content.mapProperties.sheying">{{ $t('source_3') }}：{{ content.mapProperties.sheying }}</span>
              <span v-if="content.author">{{ $t('source_4') }}：{{ content.author }}</span>
              <span v-if="content.origin">{{ $t('source_1') }}：{{ content.origin }}</span>
              <span v-if="content.publishTime">{{$moment(content.publishTime).format('YYYY-MM-DD')}}</span>
            </div>
            <div class="content 12" v-html="contentHtml"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      details: false,
      contentHtml: '',
      title: this.$t('schoolInfor_3'),
      content: ''
    };
  },
  created() {
    this.getList();
    this.details = false
  },
  methods: {
    toDetail(item) {
      this.details = true
      this.title = item.title
      this.contentHtml = item.content
      this.content = item
    },
    async getList() {
      const data1 = {
        pageNumber: 0,
        pageSize: 100,
        columnIds: this.$columnIdFile.xxgkzmxz,
      };
      const res = await this.API.basic.getlist(data1);
      const data = res.data.content.reverse()
      this.list = data
    },
  },
};
</script>

<style lang="scss" scoped>
a{
  color: #002bfd !important;
}
img{
  max-width: 100%;
}
.content_source{
    span{
      padding-right: 16px;
    }
  }
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;
  .content {
    padding: 0 70px;
    font-size: 16px;
    ::v-deep a{
      color: #002bfd !important;
    }
    ::v-deep p {
      line-height: 50px;
      color: #333;
      font-family: 微软雅黑,Microsoft YaHei;
      font-size: 16px;
    }
    ::v-deep img {
      max-width: 600px!important;
      margin: auto !important;
    }
  }
}
.top-desc {
  padding-bottom: 20px !important;
  color: #666;
  text-indent: 2em;
  font-size: 16px;
  line-height: 180%;
}
.zmxz_item {
  border-bottom: solid 1px #ccc;
  padding: 20px 0;
  display: flex;
  align-content: space-between;
}
.zmxz_l {
  width: 20%;
  max-width: 300px;
  margin-right: 20px;
}
.zmxz_r {
  width: 80%;
  align-self: center;
}
.zmxz_t a {
  cursor: pointer;
  color: #a73827!important;
  line-height: 180%;
  font-size: 18px;
  font-weight: bold;
}
.zmxz_b a {
  cursor: pointer;
  color: #333!important;
  line-height: 180%;
  font-size: 16px;
}
.zmxz_d {
  color: #8c8c8c;
  line-height: 180%;
  font-size: 16px;
}
</style>
