import { render, staticRenderFns } from "./item5.vue?vue&type=template&id=ae78f0dc&scoped=true"
var script = {}
import style0 from "./item5.vue?vue&type=style&index=0&id=ae78f0dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae78f0dc",
  null
  
)

export default component.exports